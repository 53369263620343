// ---------------------------------------------
// ---              FE constants             ---
// ---------------------------------------------


// impostazione stile SITO e abilitazione comboBox x cambiarlo
let SITE_STYLE_ACTIVE="A"
const ENABLE_SWITCH_STILE = true;
//const ENABLE_SWITCH_STILE = false;
//const ENABLE_CREATE_ACCOUNT = false;
const ENABLE_CREATE_ACCOUNT = true;

// identificazione cliente
const CUSTOMER = "Abaco srl";
const CUSTOMER_CODE = "0001";
const CUSTOMER_VIA = "via Roma 10";
const CUSTOMER_CITTA = "20121 Milano";
const CUSTOMER_EMAIL = "informazioni@studio-professionale.it";

// stripeKey='pk_test_51OUw3IHYUETFznHdNruu2jhgzYf0wwJlSdP62oknyCMooXzDEJKRfXnNvLhJPbeFAScA69UdDvfVA5y0dQtJJ2sD00eh0tlXl0'
const STRIPE_PUBLIC_KEY_FE='pk_test_51OUw3IHYUETFznHdNruu2jhgzYf0wwJlSdP62oknyCMooXzDEJKRfXnNvLhJPbeFAScA69UdDvfVA5y0dQtJJ2sD00eh0tlXl0'

//const COLOR_TEXT_FOOTER_STYLE_A = "#F8F9FA"    // non fnz nel file css   grigione
//const COLOR_TEXT_FOOTER_STYLE_A = "#616161"    // non fnz nel file css
//const COLOR_BACKGROUND_MENU_STYLE_A = "#dadada"
const COLOR_TEXT_FOOTER_STYLE_A = "#222f7f"    // non fnz nel file css    
const COLOR_BACKGROUND_MENU1_STYLE_A = "#222f7f"   // è il primary 
const COLOR_BACKGROUND_MENU2_STYLE_A = "#e9e9e9"    // è il grigio chiaro adatto al menu2

const COLOR_TEXT_FOOTER_STYLE_B = "#F8F9FA"    // non fnz nel file css
const COLOR_BACKGROUND_MENU_STYLE_B = "#222f7f"

const COLOR_TEXT_FOOTER_STYLE_C = "#707070"    // non fnz nel file css
const COLOR_BACKGROUND_MENU1_STYLE_C = "#0D6EFD"
const COLOR_BACKGROUND_MENU2_STYLE_C = "#F8F9FA"

const COLOR_TEXT_FOOTER_STYLE_D = "#222f7f"    // non fnz nel file css
const COLOR_BACKGROUND_MENU_STYLE_D = "#222f7f"

const CUSTOMER_SLIDE_URL_ARRAY = [
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-abbigliamentodonna.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-accessoridonna.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-attrezzigiardino.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-computers.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-games.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-lavatrici.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-trapani.jpg",
  "https://www.shoppingclick.it/myplanner/storage-images/customer-0001/categorie/categoria-tablets.jpg",
];

const PAGE_COUPON_TITOLO = "seguiamo Clienti ed Iniziative di rilievo nazionale"
const PAGE_COUPON_TESTO = "Il nostro Studio ritiene che la professione debba essere sempre e costantemente al passo con le nuove tecnologie.  \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_COUPON_SLIDE_1_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide4.jpg";
const PAGE_COUPON_SLIDE_1_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_1_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_COUPON_SLIDE_2_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide5.jpg";
const PAGE_COUPON_SLIDE_2_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_2_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_COUPON_SLIDE_3_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide6.jpg";
const PAGE_COUPON_SLIDE_3_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_3_SOTTOTITOLO = "per garantirti la massima professionalità";
                
const PAGE_GIFTCARD_TITOLO = "Seguici"
const PAGE_GIFTCARD_TESTO = "Seguiamo costantemente Convegni del settore perchè conosciamo l'importanza dei continui aggiornamenti professionali. \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_GIFTCARD_SLIDE_1_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide61.jpg";
const PAGE_GIFTCARD_SLIDE_1_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_2_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide62.jpg";
const PAGE_GIFTCARD_SLIDE_2_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_3_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide63.jpg";
const PAGE_GIFTCARD_SLIDE_3_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
                
const PAGE_NEWS_TITOLO = "Seguici"
const PAGE_NEWS_TESTO = "Instauriamo con i nostri clienti una profiqua comunicazione su tutto ciò che può interessare la miglior soluzione praticabile. \
Crediamo nel continuo esame delle problematiche al fine di rettificare quanto inerente le esigenze dei Clienti. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_NEWS_SLIDE_1_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide64.jpg";
const PAGE_NEWS_SLIDE_1_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_1_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_2_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide65.jpg";
const PAGE_NEWS_SLIDE_2_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_2_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_3_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide66.jpg";
const PAGE_NEWS_SLIDE_3_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_3_SOTTOTITOLO = "pubblichiamo approfondimenti";

// prelievo IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
// =>/home/shouser10/htdocs/www.shoppingclick.it/myplanner/storage-images/site-myplanneronline-it
//const URL_IMAGES_CUSTOMER = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/";
const URL_IMAGES_CUSTOMER = "/images-"+CUSTOMER_CODE+"/";
const URL_IMAGES_CUSTOMER1 = "/images-"+CUSTOMER_CODE+"/";

module.exports = { 
  ENABLE_SWITCH_STILE,
  ENABLE_CREATE_ACCOUNT,
  SITE_STYLE_ACTIVE,
  CUSTOMER,
  CUSTOMER_CODE,
  CUSTOMER_VIA,
  CUSTOMER_CITTA,
  CUSTOMER_EMAIL,
  STRIPE_PUBLIC_KEY_FE,

  COLOR_TEXT_FOOTER_STYLE_A,
  COLOR_BACKGROUND_MENU1_STYLE_A,
  COLOR_BACKGROUND_MENU2_STYLE_A,
  COLOR_TEXT_FOOTER_STYLE_B,
  COLOR_BACKGROUND_MENU_STYLE_B,
  COLOR_TEXT_FOOTER_STYLE_C,
  COLOR_BACKGROUND_MENU1_STYLE_C,
  COLOR_BACKGROUND_MENU2_STYLE_C,
  COLOR_BACKGROUND_MENU_STYLE_D,
  COLOR_TEXT_FOOTER_STYLE_D,
  CUSTOMER_SLIDE_URL_ARRAY,

  PAGE_COUPON_TITOLO,
  PAGE_COUPON_TESTO,
  PAGE_COUPON_SLIDE_1_URL,
  PAGE_COUPON_SLIDE_1_TITOLO,
  PAGE_COUPON_SLIDE_1_SOTTOTITOLO,
  PAGE_COUPON_SLIDE_2_URL,
  PAGE_COUPON_SLIDE_2_TITOLO,
  PAGE_COUPON_SLIDE_2_SOTTOTITOLO,
  PAGE_COUPON_SLIDE_3_URL,
  PAGE_COUPON_SLIDE_3_TITOLO,
  PAGE_COUPON_SLIDE_3_SOTTOTITOLO,

  PAGE_GIFTCARD_TITOLO,
  PAGE_GIFTCARD_TESTO,
  PAGE_GIFTCARD_SLIDE_1_URL,
  PAGE_GIFTCARD_SLIDE_1_TITOLO,
  PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_2_URL,
  PAGE_GIFTCARD_SLIDE_2_TITOLO,
  PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_3_URL,
  PAGE_GIFTCARD_SLIDE_3_TITOLO,
  PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO,
   
  PAGE_NEWS_TITOLO,
  PAGE_NEWS_TESTO,
  PAGE_NEWS_SLIDE_1_URL,
  PAGE_NEWS_SLIDE_1_TITOLO,
  PAGE_NEWS_SLIDE_1_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_2_URL,
  PAGE_NEWS_SLIDE_2_TITOLO,
  PAGE_NEWS_SLIDE_2_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_3_URL,
  PAGE_NEWS_SLIDE_3_TITOLO,
  PAGE_NEWS_SLIDE_3_SOTTOTITOLO,
  
  URL_IMAGES_CUSTOMER,
  URL_IMAGES_CUSTOMER1,
};
