import { useEffect, useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { useMediaQuery } from 'react-responsive';
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";

const config = require("../constants/constants.js")
const { strCurrency, strEuroCurrency } = require('../utils/currencyUtils.js')

const ProductForListComponent = ({ productId, name, description, price, images, rating, reviewsNumber }) => {

  const isMobile = useMediaQuery({ query: '(min-width: 576px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isPC = useMediaQuery({ query: '(min-width: 992px)' });

  let imageDimensionStyle = { width: '300px', height: '300px' };
  const url_dettagliservizio = "/product-details/"+productId;
  const url_image_dettagliservizio = config.URL_IMAGES_CUSTOMER1+"dettagli-servizio.png";  
  const help_image_dettagliservizio = "dettagli del servizio "+name;  

  const navigate = useNavigate();

  let size_image_gotoPage=30;
 
  if (isMobile===true) imageDimensionStyle='';
  if (isTablet===true) imageDimensionStyle='';
  if (isPC===true) imageDimensionStyle={ width: '300px', height: '300px' };

  useEffect(() => {
    console.log({isMobile});
    console.log({isTablet});
    console.log({isPC});
  },[isMobile])

  const routeChange = (e) =>{ 
    e.preventDefault();
    navigate(url_dettagliservizio);
  }

  return (
 
    <Card body outline color="success" className="mx-2 my-3 px-0 py-0">
      
    <Box
      sx={{
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#e9e9e9',
          opacity: [0.9, 0.8, 0.7],
        },
      }}
     >

    <Row>
  
      <Col lg={5}>

        <a href={url_dettagliservizio}>
          <Card.Img
              crossOrigin="anonymous"
              variant="top"
              src={images[0] ? images[0].path : ''}
              style={imageDimensionStyle}
            />
        </a>
      
      </Col>
  
      <Col lg={7}>

        <Card.Body
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 8,
            paddingBottom: 0,
          }}>

          <Card.Title>             
            <Box
              fontSize="h6.fontSize"
              component="div" 
              overflow="hidden"            
              whiteSpace="pre-line"
              textOverflow="ellipsis"
              height={50}          
            >
              {name}
            </Box>
          </Card.Title>

          <Card.Text>

            <Box
              component="div" 
              overflow="hidden"            
              whiteSpace="pre-line"
              textOverflow="ellipsis"
            >

              <Row>

                <Col md={9} class="text-start">
                  {description}
                  <Card.Text>
                    <Rating readonly size={20} initialValue={rating} /> ({reviewsNumber ? reviewsNumber : 0})
                  </Card.Text>

                  <Card.Text className="h4">
                    {strEuroCurrency(price)}{" "}
                  </Card.Text>      
                </Col>

                <Col md={3} class="text-center">

                  <Box position="absolute" bottom="20px" right="20px">

                    <div 
                      onClick={event =>  {
                        event.preventDefault();
                        navigate(url_dettagliservizio)
                      }}
                    >
                      <img 
                        src={url_image_dettagliservizio} 
                        title={help_image_dettagliservizio}
                      />
                    </div>

                  </Box>          

                </Col>
              </Row>          

            </Box>

          </Card.Text>


        </Card.Body>

      </Col>
  
    </Row>
  
  </Box>

</Card>

);
};

export default ProductForListComponent;
