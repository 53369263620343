import { BrowserRouter, Routes, Route } from "react-router-dom";

// components:
import HeaderComponent1StyleA from "./components/HeaderComponent1styleA";
import HeaderComponent1StyleB from "./components/HeaderComponent1styleB";
import HeaderComponent2StyleB from "./components/HeaderComponent2styleB";
import HeaderComponent1StyleC from "./components/HeaderComponent1styleC";
import HeaderComponent2StyleC from "./components/HeaderComponent2styleC";
import HeaderComponent1StyleD from "./components/HeaderComponent1styleD";
import FooterComponentStyleA from "./components/FooterComponentStyleA";
import FooterComponentStyleB from "./components/FooterComponentStyleB";
import FooterComponentStyleC from "./components/FooterComponentStyleC";
import FooterComponentStyleD from "./components/FooterComponentStyleD";

//user components:
import RoutesWithUserChatComponent from "./components/user/RoutesWithUserChatComponent";

// publicly available pages:
import HomePage from "./pages/HomePage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import ProductListPage from "./pages/ProductListPage";
import CartPage from "./pages/CartPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";

import ProtectedRoutesComponent from "./components/ProtectedRoutesComponent";

// protected user pages:
import UserProfilePage from "./pages/user/UserProfilePage";
import UserOrdersPage from "./pages/user/UserOrdersPage";
import UserCartDetailsPage from "./pages/user/UserCartDetailsPage";
import UserOrderDetailsPage from "./pages/user/UserOrderDetailsPage";
import ContattiPage from "./pages/ContattiPage";
import ChiSiamoPage from "./pages/ChiSiamoPage";
import CouponsPage from "./pages/CouponsPage";
import GiftCardPage from "./pages/GiftCardPage";
import HomeMenuPage from "./pages/HomeMenuPage";
import BrandsPage from "./pages/StaffPage.js"; 
import ProdottiPage from "./pages/ProdottiPage";
import NewsPage from "./pages/NewsPage";

// protected admin pages:
import AdminUsersPage from "./pages/admin/AdminUsersPage";
import AdminEditUserPage from "./pages/admin/AdminEditUserPage";
import AdminProductsPage from "./pages/admin/AdminProductsPage";
import AdminCategoriesPage from "./pages/admin/AdminCategoriesPage"; 
import AdminMessagesPage from "./pages/admin/AdminMessagesPage.js";
import AdminResourcesPage from "./pages/admin/AdminResourcesPage.js";
import AdminPaymentsPage from "./pages/admin/AdminPaymentsPage.js";
import AdminCustomersPage from "./pages/admin/AdminCustomersPage.js";
import AdminSitesPage from "./pages/admin/AdminSitesPage.js";
import AdminCreateProductPage from "./pages/admin/AdminCreateProductPage";
import AdminEditProductPage from "./pages/admin/AdminEditProductPage";
import AdminCreateCategoryPage from "./pages/admin/AdminCreateCategoryPage.js";
import AdminCreateMessagePage from "./pages/admin/AdminCreateMessagePage.js";
import AdminCreateResourcePage from "./pages/admin/AdminCreateResourcePage.js";
import AdminCreateCustomerPage from "./pages/admin/AdminCreateCustomerPage.js";
import AdminCreateUserPage from "./pages/admin/AdminCreateUserPage.js";
import AdminCreateSitePage from "./pages/admin/AdminCreateSitePage.js";
import AdminEditCategoryPage from "./pages/admin/AdminEditCategoryPage.js";
import AdminEditMessagePage from "./pages/admin/AdminEditMessagePage.js";
import AdminEditResourcePage from "./pages/admin/AdminEditResourcePage.js";
import AdminEditPaymentPage from "./pages/admin/AdminEditPaymentPage.js";
import AdminEditCustomerPage from "./pages/admin/AdminEditCustomerPage.js";
import AdminEditSitePage from "./pages/admin/AdminEditSitePage.js";
import AdminOrdersPage from "./pages/admin/AdminOrdersPage";
import AdminOrderDetailsPage from "./pages/admin/AdminOrderDetailsPage";
import AdminChatsPage from "./pages/admin/AdminChatsPage";
import AdminAnalyticsPage from "./pages/admin/AdminAnalyticsPage";
import AdminConfigsPage from "./pages/admin/AdminConfigsPage.js";
import ScrollToTop from "./utils/ScrollToTop";

import AdminCreateCouponPage from "./pages/admin/AdminCreateCouponPage.js";
import AdminEditCouponPage from "./pages/admin/AdminEditCouponPage.js";
import AdminCreateGiftcardPage from "./pages/admin/AdminCreateGiftcardPage.js";
import AdminEditGiftcardPage from "./pages/admin/AdminEditGiftcardPage.js";
import AdminCouponsPage from "./pages/admin/AdminCouponsPage.js";
import AdminGiftcardsPage from "./pages/admin/AdminGiftcardsPage.js";

import axios from "axios";

const config = require("./constants/constants.js")
const url = window.location.href;

// ------------ INI ----------- configurazione dal DB

const getConfigByProgressivoApiRequest = async (progressivoRagSoc) => {
  console.log("[getConfigByProgressivoApiRequest] leggo const con progressivo =",progressivoRagSoc);
  const url = `/api/configs/progressivo/`+progressivoRagSoc;
  const { data } = await axios.get(url);
  return data
}

var configurazione = {
  ragioneSociale:'',
  cliente:'',
  partitaIva:'',
  indirizzo:'',
  cap:'',
  citta:'',
  provincia:'',
  telefono:'',
  telefono1:'',
  telefono2:'',
  telefono3:'',
  mobile:'',
  mobile1:'',
  mobile2:'',
  mobile3:'',
  email:'',
  email1:'',
  email2:'',
  email3:'',
  imageLogo:'',
  aliquotaIva:'',
  aliquotaIvaRid:'',
  aliquotaIvaMin:'',
  aliquotaIva1:'',
  aliquotaIva2:'',
  aliquotaIva3:'',
  customerCode:'',
  titleProduct:'',
  siteStyleActive:'',
  enableSwitchStyle:'',
  urlSiteImageServer:'',
  urlCustomerImageServer:'',
};

function loadAppConfiguration() {
  getConfigByProgressivoApiRequest('0001').then((data) => { 

  //console.log(`data =`, data);  
  //console.log(`[readAppConstants] ragioneSociale =`, data?.ragioneSociale);  
  //console.log(`[readAppConstants] cliente =`, data?.cliente);  

  configurazione.ragioneSociale=data.ragioneSociale;
  configurazione.cliente=data.cliente;
  configurazione.partitaIva=data.partitaIva;
  configurazione.indirizzo=data.indirizzo;
  configurazione.cap=data.cap;
  configurazione.citta=data.citta;
  configurazione.provincia=data.provincia;
  configurazione.telefono=data.telefono;
  configurazione.telefono1=data.telefono1;
  configurazione.telefono2=data.telefono2;
  configurazione.telefono3=data.telefono3;
  configurazione.mobile=data.mobile;
  configurazione.mobile1=data.mobile1;
  configurazione.mobile2=data.mobile2;
  configurazione.mobile3=data.mobile3;
  configurazione.email=data.email;
  configurazione.email1=data.email1;
  configurazione.email2=data.email2;
  configurazione.email3=data.email3;
  configurazione.imageLogo=data.imageLogo;
  configurazione.aliquotaIva=data.aliquotaIva;
  configurazione.aliquotaIvaRid=data.aliquotaIvaRid;
  configurazione.aliquotaIvaMin=data.aliquotaIvaMin;
  configurazione.aliquotaIva1=data.aliquotaIva1;
  configurazione.aliquotaIva2=data.aliquotaIva2;
  configurazione.aliquotaIva3=data.aliquotaIva3;
  configurazione.customerCode=data.customerCode;
  configurazione.titleProduct=data.titleProduct;
  configurazione.siteStyleActive=data.siteStyleActive;
  configurazione.enableSwitchStyle=data.enableSwitchStyle;
  configurazione.urlSiteImageServer=data.urlSiteImageServer;
  configurazione.urlCustomerImageServer=data.urlCustomerImageServer;

  return configurazione;
})
.catch((er) => {
  console.log(er);
});  
}

//loadAppConfiguration();
//console.log('[App] configurazione =',configurazione); 

// ------------ FIN ----------- configurazione dal DB

// thema da configurazione
let site_style_active = config.SITE_STYLE_ACTIVE;
// esecuzione locale
if (url.includes("http://localhost:3000")) site_style_active = "B";
// esecuzione prod
if (url.includes("myplanneronline.it")) site_style_active = "A";
// esecuzione prod subdomains
if (url.includes("classic.myplanneronline")) site_style_active = "A";
if (url.includes("modern.myplanneronline")) site_style_active = "B";
if (url.includes("jazz.myplanneronline")) site_style_active = "C";
if (url.includes("time.myplanneronline")) site_style_active = "D";

console.log(url);
console.log('This will fire1!');
console.log(site_style_active);

/*
if (url.includes("http://localhost:3000")) site_style_active = "A";  // menu 1 MIN + footer, tutto sfondo grigio chiaro
if (url.includes("http://localhost:3000")) site_style_active = "B";  // menu 1 + menu 2 MIN + footer, tutto sfondo blu scuro     //  DEFAULT
if (url.includes("http://localhost:3000")) site_style_active = "C";  // menu 1 sfondo blu chiaro + menu 2 MAUSC e footer sfondo bianchetto 
if (url.includes("http://localhost:3000")) site_style_active = "D";  // menu 1 MIN + footer,  sfondo blu scuro
*/
if (url.includes("http://localhost:3000")) site_style_active = "A";

function App() {
  return (
    
    <BrowserRouter>

    <ScrollToTop />

      {site_style_active === 'A' && <HeaderComponent1StyleA />}
      {site_style_active === 'B' && <HeaderComponent1StyleB />}
      {site_style_active === 'B' && <HeaderComponent2StyleB />}
      {site_style_active === 'C' && <HeaderComponent1StyleC />}
      {site_style_active === 'C' && <HeaderComponent2StyleC />}
      {site_style_active === 'D' && <HeaderComponent1StyleD />}

      <Routes>
        <Route element={<RoutesWithUserChatComponent />}>
          {/* publicly available routes: */}
          <Route path="/" element={<HomeMenuPage />} />
          <Route path="/product-list" element={<ProductListPage />} />
          <Route path="/product-list/:pageNumParam" element={<ProductListPage />} />
          <Route path="/product-list/category/:categoryName" element={<ProductListPage />} />
          <Route path="/product-list/category/:categoryName/:pageNumParam" element={<ProductListPage />} />
          <Route path="/product-list/search/:searchQuery" element={<ProductListPage />} />
          <Route path="/product-list/search/:searchQuery/:pageNumParam" element={<ProductListPage />} />
          <Route path="/product-list/category/:categoryName/search/:searchQuery" element={<ProductListPage />} />
          <Route path="/product-list/category/:categoryName/search/:searchQuery/:pageNumParam" element={<ProductListPage />} />
          <Route path="/product-details/:id" element={<ProductDetailsPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />

          <Route path="/home-menu" element={<HomeMenuPage />} />
          <Route path="/staff" element={<BrandsPage />} />
          <Route path="/contatti" element={<ContattiPage />} />
          <Route path="/chi-siamo" element={<ChiSiamoPage />} />
          
          <Route path="/servizi" element={<HomePage />} />
          <Route path="/appuntamenti" element={<ProdottiPage />} />

          <Route path="/convegni-eventi" element={<GiftCardPage />} />
          <Route path="/convegni-argomenti" element={<GiftCardPage />} />
          <Route path="/convegni-date" element={<GiftCardPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="*" element="Page not exists 404" />
        </Route>

        {/* user protected routes: */}
        <Route element={<ProtectedRoutesComponent isAdmin={true} />}>
          <Route path="/user" element={<UserProfilePage />} />
          <Route path="/user/my-orders" element={<UserOrdersPage />} />
          <Route path="/user/cart-details" element={<UserCartDetailsPage />} />
          <Route path="/user/order-details/:id" element={<UserOrderDetailsPage />} />
        </Route>

        {/* admin protected routes: */}
        <Route element={<ProtectedRoutesComponent isAdmin={false} />}>
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/admin/create-new-user" element={<AdminCreateUserPage />} />
          <Route path="/admin/edit-user/:id" element={<AdminEditUserPage />} />

          <Route path="/admin/products" element={<AdminProductsPage />} />
          <Route path="/admin/create-new-product" element={<AdminCreateProductPage />} />
          <Route path="/admin/edit-product/:id" element={<AdminEditProductPage />} />

          <Route path="/admin/categories" element={<AdminCategoriesPage />} />
          <Route path="/admin/create-new-category" element={<AdminCreateCategoryPage />} />
          <Route path="/admin/edit-category/:id" element={<AdminEditCategoryPage />} />

          <Route path="/admin/messages" element={<AdminMessagesPage />} />
          <Route path="/admin/create-new-message" element={<AdminCreateMessagePage />} />
          <Route path="/admin/edit-message/:id" element={<AdminEditMessagePage />} />

          <Route path="/admin/resources" element={<AdminResourcesPage />} />
          <Route path="/admin/create-new-resource" element={<AdminCreateResourcePage />} />
          <Route path="/admin/edit-resource/:id" element={<AdminEditResourcePage />} />

          <Route path="/admin/coupons" element={<AdminCouponsPage />} />
          <Route path="/admin/create-new-coupon" element={<AdminCreateCouponPage />} />
          <Route path="/admin/edit-coupon/:id" element={<AdminEditCouponPage />} />

          <Route path="/admin/giftcards" element={<AdminGiftcardsPage />} />
          <Route path="/admin/create-new-giftcard" element={<AdminCreateGiftcardPage />} />
          <Route path="/admin/edit-giftcard/:id" element={<AdminEditGiftcardPage />} />

          <Route path="/admin/payments" element={<AdminPaymentsPage />} />
          <Route path="/admin/edit-payment/:id" element={<AdminEditPaymentPage />} />

          <Route path="/admin/customers" element={<AdminCustomersPage />} />
          <Route path="/admin/create-new-customer" element={<AdminCreateCustomerPage />} />
          <Route path="/admin/edit-customer/:id" element={<AdminEditCustomerPage />} />

          <Route path="/admin/sites" element={<AdminSitesPage />} />
          <Route path="/admin/create-new-site" element={<AdminCreateSitePage />} />
          <Route path="/admin/edit-site/:id" element={<AdminEditSitePage />} />

          <Route path="/admin/orders" element={<AdminOrdersPage />} />
          <Route
            path="/admin/order-details/:id"
            element={<AdminOrderDetailsPage />}
          />
          <Route path="/admin/chats" element={<AdminChatsPage />} />
          <Route path="/admin/analytics" element={<AdminAnalyticsPage />} />
          <Route path="/admin/configs" element={<AdminConfigsPage />} />
        </Route>
      </Routes>

      {site_style_active === 'A' && <FooterComponentStyleA />}
      {site_style_active === 'B' && <FooterComponentStyleB />}
      {site_style_active === 'C' && <FooterComponentStyleC />}
      {site_style_active === 'D' && <FooterComponentStyleD />}

    </BrowserRouter>
  );
}

export default App;
