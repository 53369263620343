import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
  Table,
  Alert,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { changeCategory, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const onHover = {
  cursor: "pointer",
  position: "absolute",
  left: "5px",
  top: "-10px",
  transform: "scale(2.7)",
};

const EditProductPageComponent = ({
  categories,
  fetchProduct,
  updateProductApiRequest,
  reduxDispatch,
  saveAttributeToCatDoc,
  imageDeleteHandler,
  uploadHandler,
  uploadImagesApiRequest,
  uploadImagesCloudinaryApiRequest
}) => {
  const [validated, setValidated] = useState(false);
  const [product, setProduct] = useState({});
  const [updateProductResponseState, setUpdateProductResponseState] = useState({
    message: "",
    error: "",
  });
  const [attributesFromDb, setAttributesFromDb] = useState([]); // for select lists
  const [attributesTable, setAttributesTable] = useState([]); // for html table
  const [categoryChoosen, setCategoryChoosen] = useState("Choose category");
  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false)
  const [isUploading, setIsUploading] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const { id } = useParams();

  const navigate = useNavigate();

  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [color, setColor] = useState("");                      
  const [count, setCount] = useState(0);                      
  const [price, setPrice] = useState(0);                      
  const [isBookable, setIsBookable] = useState(false);                      
  const [bookingPrice, setBookingPrice] = useState(0);                      

  const [showAlertConfirmDelete, setShowAlertConfirmDelete] = useState(false);
  
  useEffect(() => {
    fetchProduct(id)
      .then((product) => {
      setProduct(product)
      console.log(`product =`, product);  
      setName(product?.name);
      setDescription(product?.description);
      setColor(product?.color);
      setCount(product?.count);
      setPrice(product?.price);
      setIsBookable(product?.isBookable);
      setBookingPrice(product?.bookingPrice);
      console.log(`name  =`, name);  
      console.log(`description  =`, description); 
    })
    .catch((er) => console.log(er));
  }, [id, imageRemoved, imageUploaded]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;

    const formInputs = {
      name: name,
      description: description,
      color: color,
      count: count,
      price: price,
      isBookable: isBookable,
      bookingPrice: bookingPrice,
      category: form.category.value,
      attributesTable: attributesTable,
    };

    console.log('[handleSubmit] formInputs =',formInputs);

    if (event.currentTarget.checkValidity() === true) {
      updateProductApiRequest(id, formInputs)
        .then((data) => {
          if (data.message === "product updated") navigate("/admin/products");
        })
        .catch((er) =>
          setUpdateProductResponseState({
            error: er.response.data.message
              ? er.response.data.message
              : er.response.data,
          })
        );
    }

    setValidated(true);

    //window.location.assign('/admin/products')

  };

  useEffect(() => {
    let categoryOfEditedProduct = categories.find(
      (item) => item.name === product.category
    );
    if (categoryOfEditedProduct) {
      const mainCategoryOfEditedProduct =
        categoryOfEditedProduct.name.split("/")[0];
      const mainCategoryOfEditedProductAllData = categories.find(
        (categoryOfEditedProduct) =>
          categoryOfEditedProduct.name === mainCategoryOfEditedProduct
      );
      if (
        mainCategoryOfEditedProductAllData &&
        mainCategoryOfEditedProductAllData.attrs.length > 0
      ) {
        setAttributesFromDb(mainCategoryOfEditedProductAllData.attrs);
      }
    }
    setCategoryChoosen(product.category);
    setAttributesTable(product.attrs);
  }, [product]);

  const attributeValueSelected = (e) => {
      if (e.target.value !== "Choose attribute value") {
          setAttributesTableWrapper(attrKey.current.value, e.target.value, setAttributesTable);
      }
  }

  const deleteAttribute = (key) => {
      setAttributesTable((table) => table.filter((item) => item.key !== key));
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

  const newAttrKeyHandler = (e) => {
      e.preventDefault();
      setNewAttrKey(e.target.value);
      addNewAttributeManually(e);
  }

  const newAttrValueHandler = (e) => {
      e.preventDefault();
      setNewAttrValue(e.target.value);
      addNewAttributeManually(e);
  }

  const addNewAttributeManually = (e) => {
      if (e.keyCode && e.keyCode === 13) {
          if (newAttrKey && newAttrValue) {
              reduxDispatch(saveAttributeToCatDoc(newAttrKey, newAttrValue, categoryChoosen));
             setAttributesTableWrapper(newAttrKey, newAttrValue, setAttributesTable);
             e.target.value = "";
             createNewAttrKey.current.value = "";
             createNewAttrVal.current.value = "";
             setNewAttrKey(false);
             setNewAttrValue(false);
          }
      }
  }

  useEffect(() => {
    setColor(product.color);
    //console.log('color = ',category)
  }, [])

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();
  
    window.location.assign('/admin/products')
  }
  
  const switchIsBookableHandler = (event) => {
    setIsBookable(event.target.checked);
    console.log('[switch] isBookable =',isBookable)
  };

  return (
    <Container>

      <Row className="justify-content-md-center mt-5">
        
        {/* 
        <Col md={1}>
          <Link to="/admin/products" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        */} 

        <Col md={11}>

          <h1>Modifica servizio</h1>

          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>

          <Box
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

            <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name}
              style={{
                width: "400px",
              }}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />

             <TextField id="id-description" label="Descrizione" variant="outlined" value={description} defaultValue={description} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />

            <TextField id="id-color" label="Colore" variant="outlined" value={color} defaultValue={color} type="color"
              style={{
                width: "200px",
              }}
              onChange={(event) => {
                setColor(event.target.value);
              }}
            />

            <TextField id="id-count" label="Quantità" variant="outlined" type="number" value={count} defaultValue={count} 
              style={{
                width: "200px",
              }}
              onChange={(event) => {
                setCount(event.target.value);
              }}
            />

            <FormControl sx={{ m: 1 }}
              style={{
                width: "200px",
              }}    
              onChange={(event) => {
                setPrice(event.target.value);
              }}        
            >
              <InputLabel htmlFor="outlined-adornment-amount">Prezzo</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                label="Prezzo"
                type="number"
                defaultValue={price}
                value={price}
              />
            </FormControl>

            <Row> 
                <Col md={8}>
                  <FormControlLabel 
                    control={<Switch defaultChecked />} 
                    checked={isBookable} 
                    onChange={switchIsBookableHandler} 
                    label="Prenotabile" 
                  />           
                </Col>

                <Col md={8}>
                  <Alert
                      show={isBookable}
                      variant="success"
                      onClose={() => setShowAlertConfirmDelete(false)} 
                      style={{
                        width: "40ch",
                      }}    
                >              

                    <FormControl sx={{ m: 1 }}
                        style={{
                          width: "20ch",
                        }}    
                        onChange={(event) => {
                          setBookingPrice(event.target.value);
                        }}        
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">Importo prenotazione</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          startAdornment={<InputAdornment position="start">€</InputAdornment>}
                          label="Importo prenotazione"
                          type="number"
                          defaultValue={bookingPrice}
                          value={bookingPrice}
                        />
                    </FormControl>
                  </Alert>
              </Col>

            </Row>


            </Box>

             <Form.Group className="mb-3" controlId="formBasicCategory">
              <Form.Label>Categoria</Form.Label>
              <Form.Select
                required
                name="category"
                aria-label="Default select example"
                onChange={(e) => changeCategory(e, categories, setAttributesFromDb, setCategoryChoosen)}
              >
                <option value="Choose category">Scegli categoria</option>
                {categories.map((category, idx) => {
                  return product.category === category.name ? (
                    <option selected key={idx} value={category.name}>
                      {category.name}
                    </option>
                  ) : (
                    <option key={idx} value={category.name}>
                      {category.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            {attributesFromDb.length > 0 && (
              <Row className="mt-5">
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicAttributes">
                    <Form.Label>Scegli attributo e imposta valore</Form.Label>
                    <Form.Select
                      name="atrrKey"
                      aria-label="Default select example"
                      ref={attrKey}
                      onChange={(e)=>setValuesForAttrFromDbSelectForm(e, attrVal, attributesFromDb)}
                    >
                      <option>Scegli attributo</option>
                      {attributesFromDb.map((item, idx) => (
                        <Fragment key={idx}>
                          <option value={item.key}>{item.key}</option>
                        </Fragment>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicAttributeValue"
                  >
                    <Form.Label>Valore attributo</Form.Label>
                    <Form.Select
                      name="atrrVal"
                      aria-label="Default select example"
                      ref={attrVal}
                      onChange={attributeValueSelected}
                    >
                      <option>Scegli il valore dell'attributo</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              {attributesTable && attributesTable.length > 0 && (
                <Table hover>
                  <thead>
                    <tr>
                      <th>Attributo</th>
                      <th>Valore</th>
                      <th>Cancella</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attributesTable.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.key}</td>
                        <td>{item.value}</td>
                        <td>
                          <CloseButton onClick={() => deleteAttribute(item.key)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicNewAttribute">
                  <Form.Label>Nuovo attributo</Form.Label>
                  <Form.Control
                  ref={createNewAttrKey}
                    disabled={categoryChoosen === "Choose category"}
                    placeholder="scegli o crea una categoria"
                    name="newAttrKey"
                    type="text"
                    onKeyUp={newAttrKeyHandler}
                    required={newAttrValue}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicNewAttributeValue"
                >
                  <Form.Label>Attribute value</Form.Label>
                  <Form.Control
                  ref={createNewAttrVal}
                    disabled={categoryChoosen === "Choose category"}
                    placeholder="scegli o crea una categoria"
                    required={newAttrKey}
                    name="newAttrValue"
                    type="text"
                    onKeyUp={newAttrValueHandler}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Alert show={newAttrKey && newAttrValue} variant="primary">
              Dopo aver digitato la chiave e il valore dell'attributo, premere Invio su uno dei campi
            </Alert>

            <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
              <Form.Label>Immagini</Form.Label>
              <Row>
                {product.images &&
                  product.images.map((image, idx) => (
                    <Col key={idx} style={{ position: "relative" }} xs={3}>
                      <Image
                        crossOrigin="anonymous"
                        src={image.path ?? null}
                        fluid
                      />
                      <i style={onHover} onClick={() => imageDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                    </Col>
                  ))}
              </Row>

              <Form.Control  type="file" multiple onChange={e => {
                 setIsUploading("upload files in corso ..."); 
                 if (process.env.NODE_ENV !== "production") {
                     uploadImagesApiRequest(e.target.files, id)
                     .then(data => {
                         setIsUploading("upload files completato");
                         setImageUploaded(!imageUploaded);
                     })
                      .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                 } else {
                    uploadImagesApiRequest(e.target.files, id)
                    .then(data => {
                        setIsUploading("upload files completato");
                        setImageUploaded(!imageUploaded);
                    })
                    .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                  }
              }} />

               {isUploading}

            </Form.Group>


           <Row className="justify-content-center mt-5">
            
              <Col md={2}>
                <Button 
                    type="button"
                    className="btn btn-warning"
                    onClick={(e) => {gotoIndietro(e); }}
                    style={{
                      width: "80px",
                    }}
                  >
                    Indietro
                </Button>  
              </Col>

              <Col md={2}>
                <Button variant="primary" type="submit">
                  Modifica
                </Button>
                {updateProductResponseState.error ?? ""}
             </Col>
             </Row>

           </Form>

        </Col>
      </Row>


      <Row className="mt-5 justify-content-md-center">
        <Col md={6}>
        </Col>
      </Row>

    </Container>
  );
};

export default EditProductPageComponent;
