import { useState, useEffect } from "react";
import axios from "axios";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/userActions.js";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../redux/actions/categoryActions.js";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faXTwitter } from "@fortawesome/free-brands-svg-icons"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const config = require("../constants/constants.js")
let site_style_active = config.SITE_STYLE_ACTIVE;

const HeaderComponent1styleA = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userRegisterLogin);
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  const { categories } = useSelector((state) => state.getCategories);
  const { messageReceived } = useSelector((state) => state.adminChat);

  const [searchCategoryToggle, setSearchCategoryToggle] = useState("Tutte le categorie");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const url_customer_image_tel = config.URL_IMAGES_CUSTOMER+"tel-cust"+config.CUSTOMER_CODE+".png";  // logo-cust0001.png 
  const url_customer_image_logo = config.URL_IMAGES_CUSTOMER+"logo-cust"+config.CUSTOMER_CODE+".png";  // logo-cust0001.png 

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const submitHandler = (e) => {
     if (e.keyCode && e.keyCode !== 13) return;
     e.preventDefault();
     if (searchQuery.trim()) {
         if (searchCategoryToggle === "Tutte le categorie") {
             navigate(`/product-list/search/${searchQuery}`);
         } else {
             navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}/search/${searchQuery}`);
         }
     } else if (searchCategoryToggle !== "Tutte le categorie") {
         navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}`);
     } else {
         navigate("/product-list");
     }
  }

  useEffect(() => {
      if (userInfo.isAdmin) {
          var audio = new Audio("/audio/chat-msg.mp3");
          const socket = socketIOClient();
          socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
          socket.on("server sends message from client to admin", ({user, message}) => {
              dispatch(setSocket(socket));
        //   let chatRooms = {
        //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
        //   };
            dispatch(setChatRooms(user, message));      
             dispatch(setMessageReceived(true));  
             audio.play();
          })
          socket.on("disconnected", ({reason, socketId}) => {
            //   console.log(socketId, reason)
            dispatch(removeChatRoom(socketId));
          })
          return () => socket.disconnect();
      }
  },[userInfo.isAdmin])

  // CAMBIO STILE DEL SITO : goto subdomains
  // 
  //    switch stile: 
  //        CLASSIC   A       subdomain   classic.
  //        JAZZ      B       subdomain   jazz.
  //        MODERN    C       subdomain   modern.
  //        TIME      A       subdomain   time.
  // 
  const handleSelectStyle=(value)=>{
    console.log('Style change in progress!')
    //console.log(value);
    site_style_active=value;
    //console.log(site_style_active)
    if(site_style_active === 'A'){
      //console.log('goto Classic style')
      window.location = "https://classic.myplanneronline.it/"
    } else if(site_style_active === 'B') {
      //console.log('goto Jazz style')
      window.location = "https://jazz.myplanneronline.it/"
    } else if(site_style_active === 'C') {
      //console.log('goto Modern style')
      window.location = "https://modern.myplanneronline.it/"
    } else if(site_style_active === 'D') {
      //console.log('goto Time style')
      window.location = "https://time.myplanneronline.it/"
    }
  } 
  
  // CAMBIO STILE DEL SITO
  // 
  //    se config.ENABLE_SWITCH_STILE = true   => appare un combobox per la selezione dello stile e poi va al subdomain      
  //    se config.ENABLE_SWITCH_STILE = false  => non appare un combobox per la selezione dello stile       
  //
  function SwitchStile(props) {
    if (!props) {
      return null;
    }
    return (
    <NavDropdown title="Stile" id="collasible-nav-dropdown" onSelect={handleSelectStyle}>
        <NavDropdown.Item eventKey="A"> Classic </NavDropdown.Item>
        <NavDropdown.Item eventKey="B"> Jazz</NavDropdown.Item>
        <NavDropdown.Item eventKey="C"> Modern </NavDropdown.Item>
        <NavDropdown.Item eventKey="D"> Time </NavDropdown.Item>
    </NavDropdown>
    );
  }


  const [ragioneSociale, setRagioneSociale] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [telefonoCallFromSite, setTelefonoCallFromSite] = useState("");     
  const [linkWhatsapp, setLinkWhatsapp] = useState("");     
  const [linkFacebook, setLinkFacebook] = useState("");     
  const [linkInstagram, setLinkInstagram] = useState("");     
  const [linkTwitter, setLinkTwitter] = useState("");     
  const [linkLinkedin, setLinkLinkedin] = useState("");     
  const [urlSiteImageServer, setUrlSiteImageServer] = useState("");                      
  const [urlCustomerImageServer, setUrlCustomerImageServer] = useState("");                      
  
  const getConfigByProgressivoApiRequest = async (progressivoRagSoc) => {
      console.log("[getConfigByProgressivoApiRequest] leggo const con progressivo =",progressivoRagSoc);
      const url = `/api/configs/progressivo/`+progressivoRagSoc;
      const { data } = await axios.get(url);
      return data
  }
       
useEffect(() => {
  getConfigByProgressivoApiRequest('0001').then((data) => { 
      console.log(`data =`, data);  
      setRagioneSociale(data?.ragioneSociale);
      setEmail(data?.email);
      setTelefonoCallFromSite("tel:"+data?.telefonoCallFromSite)
      setLinkWhatsapp("https://wa.me/"+data?.linkWhatsapp);
      setLinkFacebook(data?.linkFacebook);
      setLinkInstagram(data?.linkInstagram);
      setLinkTwitter(data?.linkTwitter);
      setLinkLinkedin(data?.linkLinkedin);          
      setUrlSiteImageServer(data?.urlSiteImageServer);
      setUrlCustomerImageServer(data?.urlCustomerImageServer);
      console.log(`data?.linkWhatsapp =`, data?.linkWhatsapp);  
      console.log(`data?.linkFacebook =`, data?.linkFacebook);  
      console.log(`data?.linkInstagram =`, data?.linkInstagram);  
      console.log(`data?.linkTwitter =`, data?.linkTwitter);  
      console.log(`ragioneSociale =`, data?.ragioneSociale);  
      console.log(`urlCustomerImageServer =`, data?.urlCustomerImageServer);  
  })
  .catch((er) => {
      console.log(er);
  });  
}, [ragioneSociale,setEmail,setUrlCustomerImageServer])


  return (
   
    <Navbar collapseOnSelect expand="lg" variant="dark"
     style={{
        background: config.COLOR_BACKGROUND_MENU1_STYLE_A,
        paddingTop: "0px",
        paddingBottom: "0px",
      }}
     >

      <Container>
    
        <LinkContainer to="/appuntamenti">
          <Navbar.Brand href="/appuntamenti"> 
            <img src={url_customer_image_logo} alt="bug" height={60} />       
          </Navbar.Brand>
        </LinkContainer>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       
        <Navbar.Collapse id="responsive-navbar-nav">     

          <Nav className="justify-content-end" style={{ width: "100%" }}>
            
            <LinkContainer to="/home-menu">
                <Nav.Link className="mx-0">Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/appuntamenti">
                    <Nav.Link className="mx-0">Appuntamenti</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/servizi">
                    <Nav.Link className="mx-0">Servizi</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/chi-siamo">
                    <Nav.Link className="mx-0">Chi&nbsp;siamo</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/staff">
                    <Nav.Link className="mx-0">Staff</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contatti">
                    <Nav.Link className="mx-0">Contatti</Nav.Link>
            </LinkContainer>

            {userInfo.isAdmin ? (
              <LinkContainer to="/admin/categories">
                <Nav.Link>
                  Admin
                  {messageReceived && <span className="position-absolute top-1 start-10 translate-middle p-2 bg-danger border border-light rounded-circle"></span>}
                  
                </Nav.Link>
              </LinkContainer>
            ) : userInfo.name && !userInfo.isAdmin ? (
              <NavDropdown
                title={`${userInfo.name} ${userInfo.lastName}`}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item eventKey="/user" as={Link} to="/user">
                  Profilo
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => dispatch(logout())}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}

          </Nav>

        {/* 
          <LinkContainer to="/product-list">
            <Navbar.Brand href="/product-list">
              <img src={url_customer_image_tel} alt="bug" height={40} 
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                 }}
              />                   
            </Navbar.Brand>
          </LinkContainer>

          <Nav>

            {userInfo.isAdmin ? (
              <LinkContainer to="/admin/categories">
                <Nav.Link>
                  Admin
                  {messageReceived && <span className="position-absolute top-1 start-10 translate-middle p-2 bg-danger border border-light rounded-circle"></span>}
                  
                </Nav.Link>
              </LinkContainer>
            ) : userInfo.name && !userInfo.isAdmin ? (
              <NavDropdown
                title={`${userInfo.name} ${userInfo.lastName}`}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item eventKey="/user" as={Link} to="/user">
                  Profilo
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => dispatch(logout())}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}

          </Nav>
        */}
          
          <Nav className="justify-content-end" style={{ width: "100%" }}>

            <Nav.Link href={linkFacebook} target="_blank" title="Facebook®">
                <FontAwesomeIcon icon={faFacebook}  
                            style={{
                              marginLeft: "10px",
                              color: "white",
                              height: "30px",
                            }}
                >
                </FontAwesomeIcon>
            </Nav.Link>

            <Nav.Link href={linkInstagram} target="_blank" title="Instagram®">
                <FontAwesomeIcon icon={faInstagramSquare}  
                            style={{
                                marginTop: "0px",
                                marginLeft: "10px",
                                color: "white",
                                height: "30px",
                              }}
                >
                </FontAwesomeIcon>
            </Nav.Link>

            {/*     CAMBIO STILE DEL SITO : appare il combobox di selezione Stile     */}
            {SwitchStile(config.ENABLE_SWITCH_STILE)}

            <a href={telefonoCallFromSite}>
              <img src={url_customer_image_tel} alt="chiama MPM PROJECT" title="chiama MPM PROJECT" 
                style={{
                    marginLeft: "10px",
                    height: "40px",
                }}            
              />
            </a>    

          </Nav>     

        </Navbar.Collapse>

      </Container>

    </Navbar>

);
};

export default HeaderComponent1styleA;
