import React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Typography from '@mui/material/Typography';
import axios from "axios";
import dayjs from 'dayjs'

const config = require("../../constants/constants.js")
const { strCurrency, strEuroCurrency } = require('../../utils/currencyUtils.js')
const { strDateITAfromDayJs, strDateUSAfromDayJs, strDateITAfromStrUSA, strDateUSAfromStrITA } = require('../../utils/dateUtils.js')

const PaymentCard = ({datiGiornoAgenda, indexResource, indexHour , productName, price, productBookingPrice, productImage, productProduct, productCategory, envVariable, setShowAlertPagaPrenotazione, setShowAlertConfirmPrenotazione, chiudiModale }) => {
    

    //console.log(' [PaymentCard] indexResource=',Number(indexResource));
    //console.log(' [PaymentCard] indexHour=',Number(indexHour));
    //console.log(' [PaymentCard] datiGiornoAgenda.day=',datiGiornoAgenda.day);
    //console.log(' [PaymentCard] datiGiornoAgenda=',datiGiornoAgenda);
    console.log(' [PaymentCard] envVariable=',envVariable);
            
    const product = {
        id: 2,
        name: productName,
        image: productImage,
        price: productBookingPrice,
    }

    console.log(' [PaymentCard] productName=',productName);
    console.log(' [PaymentCard] price=',price);
    console.log(' [PaymentCard] productImage=',productImage);
    console.log(' [PaymentCard] productBookingPrice=',productBookingPrice);

    const createPaymentApiRequest = async (storePaymentForm) => {
        console.log(' [createPaymentApiRequest] storePaymentForm=',storePaymentForm);
        const { data } = await axios.post(`/api/payments`, { ...storePaymentForm });
        return data;
    };     

    const createEmailPaymentApiRequest = async (emailPaymentForm) => {
        console.log(' [createPaymentApiRequest] emailPaymentForm=',emailPaymentForm);
        const { data } = await axios.post(`/api/payments/email`, { ...emailPaymentForm });
        return data;
    };     

    const updateAgendaNewAppointmentApiRequest = async (parametri) => {
        console.log(' [updateAgendaNewAppointmentApiRequest] parametri=',parametri);
        const { data } = await axios.put(`/api/agendas/id/`+parametri?._id, { ...parametri });
        return data;
      };
    
    const makePayment = async (token) => {

        const body = {
            token,
            product
        }

        //console.log("??? che porta uso  =",'%'+envVariable.trim()+'%');
        //const URL_STRIPE = "http://localhost:"+envVariable.trim()+"/payment";      
        const URL_STRIPE ='/payment';
        //console.log("URL_STRIPE =>",URL_STRIPE);

        const response = await fetch(URL_STRIPE, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        });

        console.log("`prodotto in acquisto : ",product);

        const data = await response.json();      
              
        console.log('[PaymentCard] risposta al pagamento  data=',data)
        
        if(data.status === "succeeded") {
            console.log("esito acquisto = succeeded");
            
            // ---INI---  aggiornamento DB agenda
  
            console.log('[makePayment] upd appointments         indexResource = ',Number(indexResource))
            console.log('[makePayment] upd appointments             indexHour = ',Number(indexHour))
            console.log('[makePayment] upd appointments      datiGiornoAgenda =',datiGiornoAgenda)
        
            var appointmentToUpd=[{}];
    
            console.log('[makePayment] *** modifico appuntamento id = ',datiGiornoAgenda.id);
            console.log('[makePayment] upd appointments      datiGiornoAgenda:ora  = ',datiGiornoAgenda.resource0001appointments[Number(indexHour)].hour);


            if (Number(indexResource)==0) 
            {
                //console.log(`[resource0001] day.customer =`, datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.customer);  
                datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.bookingData=dayjs(new Date()).format('YYYY-MM-DD')+' '+dayjs(new Date()).format('HH:mm:ss');
                datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.bookingPrice=productBookingPrice;
                datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.bookingCard=data?.source.brand+' ***'+data?.source.last4;
                datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.price=price;
                //console.log('[resource0001] appuntamento modificato  bookingData = '+datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.bookingData);
                //console.log('[resource0001] appuntamento modificato  bookingPrice = '+datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.bookingPrice);
                //console.log('[resource0001] appuntamento modificato  bookingCard = '+datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.bookingCard);
                //console.log('[resource0001] appuntamento modificato  price = '+datiGiornoAgenda.resource0001appointments[Number(indexHour)].appointment.price);
            }                
            if (Number(indexResource)==1) 
            {
                datiGiornoAgenda.resource0002appointments[Number(indexHour)].appointment.bookingData=dayjs(new Date()).format('YYYY-MM-DD')+' '+dayjs(new Date()).format('HH:mm:ss');
                datiGiornoAgenda.resource0002appointments[Number(indexHour)].appointment.bookingPrice=productBookingPrice;
                datiGiornoAgenda.resource0002appointments[Number(indexHour)].appointment.bookingCard=data?.source.brand+' ***'+data?.source.last4;
                datiGiornoAgenda.resource0002appointments[Number(indexHour)].appointment.price=price;
            }                
            if (Number(indexResource)==2) 
            {
                datiGiornoAgenda.resource0003appointments[Number(indexHour)].appointment.bookingData=dayjs(new Date()).format('YYYY-MM-DD')+' '+dayjs(new Date()).format('HH:mm:ss');
                datiGiornoAgenda.resource0003appointments[Number(indexHour)].appointment.bookingPrice=productBookingPrice;
                datiGiornoAgenda.resource0003appointments[Number(indexHour)].appointment.bookingCard=data?.source.brand+' ***'+data?.source.last4;
                datiGiornoAgenda.resource0003appointments[Number(indexHour)].appointment.price=price;
            }                               
            if (Number(indexResource)==3) 
            {
                datiGiornoAgenda.resource0004appointments[Number(indexHour)].appointment.bookingData=dayjs(new Date()).format('YYYY-MM-DD')+' '+dayjs(new Date()).format('HH:mm:ss');
                datiGiornoAgenda.resource0004appointments[Number(indexHour)].appointment.bookingPrice=productBookingPrice;
                datiGiornoAgenda.resource0004appointments[Number(indexHour)].appointment.bookingCard=data?.source.brand+' ***'+data?.source.last4;
                datiGiornoAgenda.resource0004appointments[Number(indexHour)].appointment.price=price;
            }                
            if (Number(indexResource)==4) 
            {
                datiGiornoAgenda.resource0005appointments[Number(indexHour)].appointment.bookingData=dayjs(new Date()).format('YYYY-MM-DD')+' '+dayjs(new Date()).format('HH:mm:ss');
                datiGiornoAgenda.resource0005appointments[Number(indexHour)].appointment.bookingPrice=productBookingPrice;
                datiGiornoAgenda.resource0005appointments[Number(indexHour)].appointment.bookingCard=data?.source.brand+' ***'+data?.source.last4;
                datiGiornoAgenda.resource0005appointments[Number(indexHour)].appointment.price=price;
            }                
            if (Number(indexResource)==5) 
            {
                datiGiornoAgenda.resource0006appointments[Number(indexHour)].appointment.bookingData=dayjs(new Date()).format('YYYY-MM-DD')+' '+dayjs(new Date()).format('HH:mm:ss');
                datiGiornoAgenda.resource0006appointments[Number(indexHour)].appointment.bookingPrice=productBookingPrice;
                datiGiornoAgenda.resource0006appointments[Number(indexHour)].appointment.bookingCard=data?.source.brand+' ***'+data?.source.last4;
                datiGiornoAgenda.resource0006appointments[Number(indexHour)].appointment.price=price;
            }                
    
            updateAgendaNewAppointmentApiRequest(datiGiornoAgenda);

            // ---FIN---  aggiornamento DB agenda
            
            // -INI---  creo nuovo payment su DB payments
           
            const storePaymentForm = 
            {
                "idPayment": data?.id,
                "productId": "67694b7f28591244ddd0dbb6",
                "productPrice": price,
                "categoryDescription": productCategory,
                "productDescription": productProduct,
                "amount": productBookingPrice,
                "date": dayjs(new Date()).format('YYYY-MM-DD'),
                "hour": dayjs(new Date()).format('HH:mm:ss'),
                "client_ip": "",
                "email": data?.source.name,
                "type": data?.source.object,
                "card": {
                    "id": data?.source.id,
                    "object": data?.source.object,
                    "brand": data?.source.brand,
                    "country": data?.source.country,
                    "cvc_check": data?.source.cvc_check,
                    "funding": data?.source.funding,
                    "last4": data?.source.last4,
                    "name": data?.source.name
                }
            }

            console.log("createPaymentApiRequest=",storePaymentForm);

            createPaymentApiRequest(storePaymentForm);

            // -FIN---  creo nuovo payment su DB payments

            // ---INI---  send email riceduta pagamento


            console.log("appointmentDate =",strDateITAfromStrUSA(datiGiornoAgenda.day));
            console.log("appointmentHour =",datiGiornoAgenda.resource0001appointments[Number(indexHour)].hour);
            
            let ora = datiGiornoAgenda.resource0001appointments[Number(indexHour)].hour;

            const emailPaymentForm = 
            {
                "appointmentDate":  strDateITAfromStrUSA(datiGiornoAgenda.day),
                "appointmentHour":  ora.substring(0,2)+":"+ora.substring(2,4),
                "idPayment": data?.id,
                "productId": "67694b7f28591244ddd0dbb6",
                "productPrice": price,
                "categoryDescription": productCategory,
                "productDescription": productProduct,
                "amount": productBookingPrice,
                "date": dayjs(new Date()).format('YYYY-MM-DD'),
                "hour": dayjs(new Date()).format('HH:mm:ss'),
                "client_ip": "",
                "email": data?.source.name,
                "type": data?.source.object,
                "card": {
                    "id": data?.source.id,
                    "object": data?.source.object,
                    "brand": data?.source.brand,
                    "country": data?.source.country,
                    "cvc_check": data?.source.cvc_check,
                    "funding": data?.source.funding,
                    "last4": data?.source.last4,
                    "name": data?.source.name
                },
                "hostTransmitter": "smtp.hostinger.com",
                "emailTransmitter": "support@mpmproject.it",
                "pswTransmitter": "Supp10mpm!",
                "emailToSend": data?.source.name
            }

            createEmailPaymentApiRequest(emailPaymentForm);

            // ---FIN---  send email riceduta pagamento

            setShowAlertPagaPrenotazione(false);
            setShowAlertConfirmPrenotazione(false);
        }
        else {
            console.log("Pagamento fallito");
        }

        chiudiModale();
        
        return data;
    }

    return (
        <div className="card rounded-0 mt-2 mb-2 ms-2 me-2" style={{width: "20rem"}}>
            <img src={productImage} className="card-img-top" alt={productName} />
            <div className="card-body">
                <h5 className="card-title">{productName}</h5>
                <Row>
                    <Col md={12} 
                        style={{
                            textAlign: "left"
                    }}>
                        <Typography sx={{ color: '#1136a0', fontSize: 18, paddingBottom: 1 }}>
                            prezzo {strEuroCurrency(price)}
                        </Typography>
                    </Col>
                 </Row>
                <StripeCheckout
                    stripeKey={config.STRIPE_PUBLIC_KEY_FE}
                    token={makePayment}
                    name={productName}>
                    <button className="btn btn-primary rounded-0 w-100">Paga acconto {strEuroCurrency(productBookingPrice)}</button>
                </StripeCheckout>
            </div>
        </div>
    )
}

export default PaymentCard
