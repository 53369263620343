import { useEffect, useState, useRef } from "react";
import { Card, Button, Row, Col, Container, ListGroup } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import AddedToCartMessageComponent from "../../components/AddedToCartMessageComponent";
import ImageZoom from "js-image-zoom";
import MetaComponent from "../../components/MetaComponent";
import { useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link, useNavigate, useHref } from "react-router-dom";

const config = require("../../constants/constants.js")
const { strCurrency, strEuroCurrency } = require('../../utils/currencyUtils.js')

const ProductDetailsPageComponent = ({
  addToCartReduxAction,
  reduxDispatch,
  getProductDetails,
  userInfo,
  writeReviewApiRequest
}) => {

  const isMobile = useMediaQuery({ query: '(min-width: 576px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isPC = useMediaQuery({ query: '(min-width: 992px)' });

  let size_image_gotoPage = 100;
  if (isMobile===true) size_image_gotoPage=150;
  if (isTablet===true) size_image_gotoPage=150;
  if (isPC===true) size_image_gotoPage=60;

  const { id } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [showCartMessage, setShowCartMessage] = useState(false);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [productReviewed, setProductReviewed] = useState(false);

  let navigate = useNavigate();

  const url_elencoServizi = "/servizi";
  const url_image_elencoServizi = config.URL_IMAGES_CUSTOMER1+"elenco-servizi.png";  
  const help_image_elencoServizi = "elenco dei servizi";  

  const messagesEndRef = useRef(null);

  const addToCartHandler = () => {
    reduxDispatch(addToCartReduxAction(id, quantity));
    setShowCartMessage(true);
  };

  useEffect(() => {
    if (productReviewed) {
        setTimeout(() => {
             messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }, 200)
    }  
  }, [productReviewed])

  useEffect(() => {
    if (product.images) {
      var options = {
        // width: 400,
        // zoomWidth: 500,
        // fillContainer: true,
        // zoomPosition: "bottom",
        scale: 2,
        offset: { vertical: 0, horizontal: 0 },
      };

      product.images.map(
        (image, id) =>
          new ImageZoom(document.getElementById(`imageId${id + 1}`), options)
      );
    }
  });

  useEffect(() => {
    getProductDetails(id)
      .then((data) => {
        setProduct(data);
        console.log('data =',data);
        setLoading(false);
      })
      .catch((er) =>
        setError(
          er.response.data.message ? er.response.data.message : er.response.data
        )
      );
  }, [id, productReviewed]);

  useEffect(() => {
    console.log({isMobile});
    console.log({isTablet});
    console.log({isPC});
  },[isMobile])
  
  const routeChange = () =>{ 
    //e.preventDefault();
    navigate(url_elencoServizi);
  }

  return (

    <Container>

      <Row className="mt-5">
        {loading ? (
          <h2>Caricamento dettagli prodotto ...</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <>

            <Col style={{ zIndex: 1 }} md={4}>

              <div class="carousel-wrapper">
                <Carousel infiniteLoop useKeyboardArrows showArrows={true}>

                {product.images.map((slide, i) => (
                  <div 
                    key={i}
                    onClick={event =>  {
                      event.preventDefault();
                      navigate(url_elencoServizi)
                    }}
                  >
                    <img
                      src={slide.path}
                      className='d-block w-100'
                      alt={slide.path}
                    />
                  </div>
                ))}

                </Carousel>
              </div>

            </Col>

            <Col md={6}>

              <Row>
                <Col md={6}>

                  <ListGroup variant="flush"
                    style={{
                      border: "0",
                    }}
                  >
                    <ListGroup.Item
                      style={{
                        border: "0",
                      }}
                    >
                      <h1>{product.name}</h1>
                    </ListGroup.Item>
                    
                    <ListGroup.Item
                      style={{
                        border: "0",
                      }}
                    >
                      <Rating
                        readonly
                        size={20}
                        initialValue={product.rating}
                      />{" "}
                      ({product.reviewsNumber ? product.reviewsNumber : 0})
                    </ListGroup.Item>

                    <ListGroup.Item
                      style={{
                        border: "0",
                      }}
                    >
                      Prezzo <span className="fw-bold">{strEuroCurrency(product.price)}</span>
                    </ListGroup.Item>
                    
                    <ListGroup.Item
                      style={{
                        border: "0",
                      }}
                    >
                      {product.description}
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
                <Col md={6}>

                <Box
                      sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                      noValidate
                      autoComplete="off"
                    >

                      <TextField id="id-isBooking" variant="outlined" value={product.isBookable === true ? "prenotabile" : "non prenotabile"} 
                        size="small"
                        slotProps={{
                          input: { readOnly: true },
                          inputLabel: { shrink: true }
                        }}             
                        onChange={(event) => {
                        //setName(event.target.value);
                        }}
                      />
                     
                      <TextField id="id-name" label="Prezzo prenotazione" variant="outlined" value={product.isBookable === true ?strEuroCurrency(product.bookingPrice): ""} 
                        size="small"
                        slotProps={{
                          input: { readOnly: true },
                          inputLabel: { shrink: true },
                        }}             
                        onChange={(event) => {
                        //setName(event.target.value);
                        }}
                      />

                  </Box>

                </Col>
              </Row>

              <Row>
                <Col className="mt-5">
                </Col>
              </Row>

              <Row className="mt-5 justify-content-md-center">

                <Col md={4}>
                </Col>
                
                <Col md={8} class="text-center">
  
                  <div 
                    onClick={event =>  {
                      event.preventDefault();
                      navigate(url_elencoServizi)
                    }}
                  >
                    <img 
                      src={url_image_elencoServizi} 
                      title={help_image_elencoServizi}
                    />
                  </div>

                </Col>

              </Row>

              <Row className="mt-5 justify-content-md-center">
                <Col md={6}>
                </Col>
              </Row>

            </Col>
          </>
        )}
      </Row>

    </Container>
    
  );
};

export default ProductDetailsPageComponent;
